import { Suspense, lazy } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Layout } from './components/layouts/Layout';
import './assets/css/main.css';
// import { Blog } from './pages/Blog';

import ScrollToTop from './components/ScrollToTop';

// import { BlogWrap } from './blogs/BlogWrap';
import { FAQ } from './pages/FAQ';
import { BSD } from './pages/BSD';
import { NotFound } from './pages/NotFound';
// const Blog = lazy(() =>
//   import('./pages/Blog').then((module) => ({
//     default: module.Blog,
//   }))
// );
const Company = lazy(() =>
  import('./pages/Company').then((module) => ({
    default: module.Company,
  }))
);
const Contact = lazy(() =>
  import('./pages/Contact').then((module) => ({
    default: module.Contact,
  }))
);

const Process = lazy(() =>
  import('./pages/Process').then((module) => ({
    default: module.Process,
  }))
);
const Services = lazy(() =>
  import('./pages/Services').then((module) => ({
    default: module.Services,
  }))
);
const StemOutreach = lazy(() =>
  import('./pages/STEM_Outreach').then((module) => ({
    default: module.StemOutreach,
  }))
);
const TurnKey = lazy(() =>
  import('./pages/TurnKey').then((module) => ({
    default: module.TurnKey,
  }))
);
const WeFit = lazy(() =>
  import('./pages/WeFit').then((module) => ({
    default: module.WeFit,
  }))
);
const WorkForce = lazy(() =>
  import('./pages/WorkForce').then((module) => ({
    default: module.WorkForce,
  }))
);
const WindTurbine = lazy(() =>
  import('./pages/WindTurbine').then((module) => ({
    default: module.WindTurbine,
  }))
);
const Bridge = lazy(() =>
  import('./pages/Bridge').then((module) => ({
    default: module.Bridge,
  }))
);
const SolarEnergy = lazy(() =>
  import('./pages/SolarEnergy').then((module) => ({
    default: module.SolarEnergy,
  }))
);
const Racecar = lazy(() =>
  import('./pages/Racecar').then((module) => ({
    default: module.Racecar,
  }))
);
const Tower = lazy(() =>
  import('./pages/Tower').then((module) => ({
    default: module.Tower,
  }))
);
const GliderPlane = lazy(() =>
  import('./pages/GliderPlane').then((module) => ({
    default: module.GliderPlane,
  }))
);
const Prosthetics = lazy(() =>
  import('./pages/Prosthetics').then((module) => ({
    default: module.Prosthetics,
  }))
);
const Hydraulics = lazy(() =>
  import('./pages/Hydraulics').then((module) => ({
    default: module.Hydraulics,
  }))
);
// const VrDriver = lazy(() =>
//   import('./pages/VrDriver').then((module) => ({
//     default: module.VrDriver,
//   }))
// );
// const ARSOME_Ventures = lazy(() =>
//   import('./pages/ARSOME_Ventures').then((module) => ({
//     default: module.ARSOME_Ventures,
//   }))
// );
const MeetTeam = lazy(() =>
  import('./pages/MeetTeam').then((module) => ({
    default: module.MeetTeam,
  }))
);
const Community = lazy(() =>
  import('./pages/Community').then((module) => ({
    default: module.Community,
  }))
);
const Media = lazy(() =>
  import('./pages/Media').then((module) => ({
    default: module.Media,
  }))
);
// const AreInPostCovid = lazy(() =>
//   import('./blogs/AreInPostCovid').then((module) => ({
//     default: module.AreInPostCovid,
//   }))
// );
// const AugmentedRealityOutdoors = lazy(() =>
//   import('./blogs/AugmentedRealityOutdoors').then((module) => ({
//     default: module.AugmentedRealityOutdoors,
//   }))
// );
// const WaysMuseumsArUsage = lazy(() =>
//   import('./blogs/WaysMuseumsArUsage').then((module) => ({
//     default: module.WaysMuseumsArUsage,
//   }))
// );
// const VirtualRealitySafe = lazy(() =>
//   import('./blogs/VirtualRealitySafe').then((module) => ({
//     default: module.VirtualRealitySafe,
//   }))
// );
// const VirtualRealityImproveDrivingTraining = lazy(() =>
//   import('./blogs/VirtualRealityImproveDrivingTraining').then((module) => ({
//     default: module.VirtualRealityImproveDrivingTraining,
//   }))
// );
// const AugmentedRealityPossibleGameMuseums = lazy(() =>
//   import('./blogs/AugmentedRealityPossibleGameMuseums').then((module) => ({
//     default: module.AugmentedRealityPossibleGameMuseums,
//   }))
// );
// const ArChildrensBooks = lazy(() =>
//   import('./blogs/ArChildrensBooks').then((module) => ({
//     default: module.ArChildrensBooks,
//   }))
// );
// const ArvrWorkplace = lazy(() =>
//   import('./blogs/ArvrWorkplace').then((module) => ({
//     default: module.ArvrWorkplace,
//   }))
// );
// const ImproveFoodIndustryPostCoronavirus = lazy(() =>
//   import('./blogs/ImproveFoodIndustryPostCoronavirus').then((module) => ({
//     default: module.ImproveFoodIndustryPostCoronavirus,
//   }))
// );
// const ArMediaPrint = lazy(() =>
//   import('./blogs/ArMediaPrint').then((module) => ({
//     default: module.ArMediaPrint,
//   }))
// );
// const ArClassroom = lazy(() =>
//   import('./blogs/ArClassroom').then((module) => ({
//     default: module.ArClassroom,
//   }))
// );
// const ArVsVr = lazy(() =>
//   import('./blogs/ArVsVr').then((module) => ({
//     default: module.ArVsVr,
//   }))
// );
// const DevelopGameSpacesuitAr = lazy(() =>
//   import('./blogs/DevelopGameSpacesuitAr').then((module) => ({
//     default: module.DevelopGameSpacesuitAr,
//   }))
// );
// const SmartwaysYearlyBudget = lazy(() =>
//   import('./blogs/SmartwaysYearlyBudget').then((module) => ({
//     default: module.SmartwaysYearlyBudget,
//   }))
// );
// const TopwaysTechnologyEducationalSpaces = lazy(() =>
//   import('./blogs/TopwaysTechnologyEducationalSpaces').then((module) => ({
//     default: module.TopwaysTechnologyEducationalSpaces,
//   }))
// );
// const NewpostPandemicClassroom = lazy(() =>
//   import('./blogs/NewpostPandemicClassroom').then((module) => ({
//     default: module.NewpostPandemicClassroom,
//   }))
// );
// const FourDynamicWaysStoryEffectively = lazy(() =>
//   import('./blogs/FourDynamicWaysStoryEffectively').then((module) => ({
//     default: module.FourDynamicWaysStoryEffectively,
//   }))
// );
// const Gamification = lazy(() =>
//   import('./blogs/Gamification').then((module) => ({
//     default: module.Gamification,
//   }))
// );
// const HomeschoolingTips = lazy(() =>
//   import('./blogs/HomeschoolingTips').then((module) => ({
//     default: module.HomeschoolingTips,
//   }))
// );
// const ArsomeOminaTechPartner = lazy(() =>
//   import('./blogs/ArsomeOminaTechPartner').then((module) => ({
//     default: module.ArsomeOminaTechPartner,
//   }))
// );
// const Phygital = lazy(() =>
//   import('./blogs/Phygital').then((module) => ({
//     default: module.Phygital,
//   }))
// );
// const MetaverseEnterprise = lazy(() =>
//   import('./blogs/MetaverseEnterprise').then((module) => ({
//     default: module.MetaverseEnterprise,
//   }))
// );
// const ImmersiveWayVisualize = lazy(() =>
//   import('./blogs/ImmersiveWayVisualize').then((module) => ({
//     default: module.ImmersiveWayVisualize,
//   }))
// );
// const TechnologyGoingNewYear = lazy(() =>
//   import('./blogs/TechnologyGoingNewYear').then((module) => ({
//     default: module.TechnologyGoingNewYear,
//   }))
// );

// const NftsAr = lazy(() =>
//   import('./blogs/NftsAr').then((module) => ({ default: module.NftsAr }))
// );
// const Top5ThingsVrHeadset = lazy(() =>
//   import('./blogs/Top5ThingsVrHeadset').then((module) => ({
//     default: module.Top5ThingsVrHeadset,
//   }))
// );
// const ArVrAndFiveG = lazy(() =>
//   import('./blogs/ArVrAndFiveG').then((module) => ({
//     default: module.ArVrAndFiveG,
//   }))
// );
// const MovingBeyondWowFactor = lazy(() =>
//   import('./blogs/MovingBeyondWowFactor').then((module) => ({
//     default: module.MovingBeyondWowFactor,
//   }))
// );

const Legal = lazy(() =>
  import('./pages/Legal').then((module) => ({ default: module.Legal }))
);
const AnimalHeroes = lazy(() =>
  import('./pages/AnimalHeroes').then((module) => ({
    default: module.AnimalHeroes,
  }))
);
const VRdriving_Corporate_Outreach = lazy(() =>
  import('./pages/Vrdriving_Corporate_Outreach').then((module) => ({
    default: module.VRdriving_Corporate_Outreach,
  }))
);
const CSR = lazy(() =>
  import('./pages/CSR').then((module) => ({
    default: module.CSR,
  }))
);
const LoadStatus = () => {
  return (
    <div className="flex items-center justify-center w-full min-h-screen bg-gray-200">
      <div className="flex items-center justify-center w-full min-h-screen bg-gray-50">
        <div className="flex items-center justify-center rounded-full h-14 w-14 bg-gradient-to-tr from-gray-50 to-blue-500 animate-spin">
          <div className="rounded-full h-9 w-9 bg-gray-50"></div>
        </div>
      </div>
    </div>
  );
};
function App() {
  return (
    <>
      <HelmetProvider>
        <Suspense fallback={<LoadStatus />}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index path="" element={<Home />} />
                <Route index path="/Home" element={<Home />} />
                {/* <Route path="blog" element={<Blog />} /> */}
                <Route path="company" element={<Company />} />
                <Route
                  path="stem_corporate_outreach"
                  element={<StemOutreach />}
                />
                <Route path="cs_corporate_outreach" element={<TurnKey />} />
                <Route path="contact" element={<Contact />} />
                <Route path="process" element={<Process />} />
                <Route path="services" element={<Services />} />
                <Route path="are_we_a_fit" element={<WeFit />} />
                <Route path="ar_vr_custom_development" element={<WorkForce />} />
                <Route path="wind_turbine" element={<WindTurbine />} />
                <Route path="bridge" element={<Bridge />} />
                <Route path="solar_energy" element={<SolarEnergy />} />
                <Route path="racecar" element={<Racecar />} />
                <Route path="tower" element={<Tower />} />
                <Route path="glider_plane" element={<GliderPlane />} />
                <Route path="prosthetics" element={<Prosthetics />} />
                <Route path="hydraulics" element={<Hydraulics />} />
                {/* <Route path="vr_driver" element={<VrDriver />} /> */}
                <Route
                  path="vrdriving_corporate_outreach"
                  element={<VRdriving_Corporate_Outreach />}
                />
                {/* <Route path="ARSOME_Ventures" element={<ARSOME_Ventures />} /> */}
                <Route path="meet_the_team" element={<MeetTeam />} />
                <Route path="community" element={<Community />} />
                <Route path="media" element={<Media />} />
                <Route path="legal" element={<Legal />} />
                <Route path="csr" element={<CSR />} />
                <Route path="bsd" element={<BSD />} />
                <Route path="faq" element={<FAQ />} />
                <Route
                  path="animal_heroes/privacy_policy"
                  element={<AnimalHeroes />}
                />
                <Route path="*" element={<NotFound />} />
              </Route>
              {/* <Route path="/blog" element={<BlogWrap />}>
                <Route path="are_in_post_covid" element={<AreInPostCovid />} />
                <Route
                  path="augmented-reality-in-the-great-outdoors"
                  element={<AugmentedRealityOutdoors />}
                />
                <Route
                  path="way_museums_ar_usage"
                  element={<WaysMuseumsArUsage />}
                />
                <Route
                  path="is-virtual-reality-safe"
                  element={<VirtualRealitySafe />}
                />
                <Route
                  path="how-can-virtual-reality-improve-driving-training"
                  element={<VirtualRealityImproveDrivingTraining />}
                />
                <Route
                  path="augmented-Reality-Possible-Game-Changer-for-Museums"
                  element={<AugmentedRealityPossibleGameMuseums />}
                />
                <Route
                  path="does-ar-childrens-books"
                  element={<ArChildrensBooks />}
                />
                <Route
                  path="ar-vr-in-the-workplace"
                  element={<ArvrWorkplace />}
                />
                <Route
                  path="how-can-augmented-reality-improve-the-food-industry-post-coronavirus"
                  element={<ImproveFoodIndustryPostCoronavirus />}
                />
                <Route path="ar-in-media-and-print" element={<ArMediaPrint />} />
                <Route path="ar-in-the-classroom" element={<ArClassroom />} />
                <Route path="ar-vs-vr" element={<ArVsVr />} />
                <Route
                  path="develop-game-changing-spacesuit-in-ar"
                  element={<DevelopGameSpacesuitAr />}
                />
                <Route
                  path="5-smart-ways-to-spend-remaining-yearly-budget"
                  element={<SmartwaysYearlyBudget />}
                />
                <Route
                  path="top-3-ways-technology-can-be-utilized-by-educational-spaces"
                  element={<TopwaysTechnologyEducationalSpaces />}
                />
                <Route
                  path="a-new-post-pandemic-reality-in-the-classroom"
                  element={<NewpostPandemicClassroom />}
                />
                <Route
                  path="four-dynamic-ways-you-can-tell-your-company-story-effectively"
                  element={<FourDynamicWaysStoryEffectively />}
                />
                <Route path="gamification" element={<Gamification />} />
                <Route
                  path="homeschooling-tips"
                  element={<HomeschoolingTips />}
                />
                <Route
                  path="arsome-omina-tech-partner-to-provide-trustworthy-ai-solution"
                  element={<ArsomeOminaTechPartner />}
                />
                <Route path="phygital" element={<Phygital />} />
                <Route
                  path="metaverse-for-enterprise"
                  element={<MetaverseEnterprise />}
                />
                <Route
                  path="a-more-immersive-way-to-visualize-data"
                  element={<ImmersiveWayVisualize />}
                />
                <Route
                  path="technology-to-look-out-for-going-into-the-new-year"
                  element={<TechnologyGoingNewYear />}
                />
                <Route path="nfts+ar" element={<NftsAr />} />
                <Route
                  path="top-5-things-to-do-with-a-vr-headset"
                  element={<Top5ThingsVrHeadset />}
                />
                <Route path="ar-vr-and-fiveg" element={<ArVrAndFiveG />} />
                <Route
                  path="moving-beyond-the-wow-factor"
                  element={<MovingBeyondWowFactor />}
                />
              </Route> */}
            </Routes>
          </BrowserRouter>
        </Suspense>
      </HelmetProvider>
    </>
  );
}

export default App;
