"use client";

import { Fragment, useMemo, useState } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import white_log from "../../assets/images/arsome_logo.svg";
import venture_log from "../../assets/images/venture_log.svg";
import { Menu, Transition } from "@headlessui/react";
import { WhiteBtn, WhiteProps } from "../LearnMore";
import { HeroWrap } from "../HeroWrap";
import org_log from "../../assets/images/company_logo.png";
import mobile_mark from "../../assets/images/mobile_mark.svg";
import { Link } from "react-router-dom";
import { showCaseStudy } from "../../store";
import { useRecoilState } from "recoil";
interface subItem {
  caption: string;
  url: string;
}
interface ISubMenus {
  title: string;
  menuItems: subItem[];
  keyNum?: number;
  curKey?: number;
  isCurLink?: boolean;
  setIsOpened?: (val: number) => void;
  isDesktopMod?: boolean;
  selected?: string;
  onAfterLink?: () => void;
}
export interface ILinkProps {
  title: string;
  link: string;
  selected: string;
  isCurOpen?: boolean;
  decorationCls?: string;
  goLink?: () => void;
}
export const HeaderBtn = (props: WhiteProps) => {
  const { title, disabled, onAction } = props;
  return (
    <button
      className={`border border-[#2E90F2] px-4 py-2 rounded-lg bg-white text-my-gray ${
        disabled && " opacity-50"
      }`}
      onClick={() => {
        if (onAction) {
          onAction(title);
        }
      }}
    >
      {title}
    </button>
  );
};
const LinkItem = ({
  title,
  link,
  selected,
  isCurOpen,
  goLink,
  decorationCls,
}: ILinkProps) => {
  const bSelected =
    (selected === link || (link === "/Home" && selected === "/")) && isCurOpen;
  return (
    <div
      className={`menu_p_item_wrap max-md:h-[61px] md:h-[71px] hover:bg-menu_blue ${decorationCls} ${
        bSelected ? "bg-menu_blue" : ""
      }`}
    >
      <NavLink
        to={link}
        onClick={() => goLink && goLink()}
        className={`${
          bSelected ? "font-bold " : "font-medium"
        } sm:text-sm xl:text-base !text-white hover: font-bold`}
      >
        {title}
      </NavLink>
    </div>
  );
};
const SubMenuItem = ({
  title,
  menuItems,
  keyNum,
  curKey,
  setIsOpened,
  isCurLink,
  selected,
  isDesktopMod,
}: ISubMenus) => {
  const isOpen = keyNum === curKey;
  return (
    <Menu as="div" className="relative">
      {({ open, close }) => (
        <div
          onMouseLeave={(ev) => {
            if (open && isDesktopMod) close();
          }}
        >
          <Menu.Button
            className={`text-sm menu_btn_item xl:text-base hover:bg-menu_blue ${
              isCurLink ? "bg-menu_blue" : ""
            }`}
            onMouseEnter={(ev) => {
              if (isDesktopMod) {
                if (!open) ev.currentTarget.click();
              }
            }}
          >
            {title} <DropdownTrigger />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="menu_items_wrap">
              <div className="grid py-1 rounded-md bg-menu_blue">
                {menuItems.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <NavLink
                        className={`sub_menu_item hover:underline ${
                          selected == item.url ? "font-bold" : "font-normal"
                        }`}
                        to={item.url}
                      >
                        {item.caption}
                      </NavLink>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};
const SunMbMenuItem = ({
  title,
  menuItems,
  keyNum,
  curKey,
  setIsOpened,
  onAfterLink,
}: ISubMenus) => {
  const isOpen = keyNum === curKey;
  return (
    <div>
      <div
        className={`text-white menu_p_item_wrap xl:px-4 md:h-[71px] max-md:py-4 ${
          isOpen && "bg-menu_blue font-bold"
        }`}
        onClick={() => {
          if (keyNum && setIsOpened) {
            setIsOpened(keyNum);
          }
        }}
      >
        {title} <DropdownTrigger />
      </div>
      {!!isOpen && (
        <div className="px-5 py-5 list-none border-t border-b border-white">
          {menuItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.url}
              onClick={() => onAfterLink && onAfterLink()}
            >
              <li className="menu_sm_item">{item.caption}</li>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};
const DropdownTrigger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12.1897 16.2131L18.1997 10.2031L16.7867 8.78809L12.1897 13.3881L7.59369 8.78809L6.17969 10.2021L12.1897 16.2131Z"
        fill="white"
      />
    </svg>
  );
};
export const Header = () => {
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showCase, setShowCase] = useRecoilState(showCaseStudy);
  const companyItems: subItem[] = [
    { caption: "Are We A Fit", url: "/are_we_a_fit" },
    { caption: "Process", url: "/process" },
    { caption: "Meet the Team", url: "/meet_the_team" },
    { caption: "Community", url: "/community" },
    { caption: "FAQ", url: "/faq" },
    // { caption: 'Media', url: '/media' },
    // { caption: 'Blog', url: '/blog' },
  ];
  const serviceItems: subItem[] = [
    { caption: "STEM Corporate Outreach", url: "/stem_corporate_outreach" },
    { caption: "CS Corporate Outreach", url: "/cs_corporate_outreach" },
    {
      caption: "VR Driving Corporate Outreach",
      url: "/vrdriving_corporate_outreach",
    },
  ];
  const [selectItem, setSelectItem] = useState(0);
  const navigate = useNavigate();
  const mobile_bg = useMemo(() => {
    if (location.pathname == "/" || location.pathname == "/home") {
      return "max-md:home_mb_bg";
    } else if (location.pathname == "/ar_vr_custom_development") {
      return "max-md:workforce_mb_bg";
    } else if (location.pathname == "/stem_corporate_outreach") {
      return "max-md:stem_outreach_mb_bg";
    } else if (location.pathname == "/cs_corporate_outreach") {
      return "max-md:cs_outreach_mb_bg";
    } else if (
      location.pathname == "/are_we_a_fit" ||
      location.pathname == "/community" ||
      location.pathname == "/contact" ||
      location.pathname == "/faq" ||
      location.pathname == "/csr"
    ) {
      return "max-md:blue_background";
    } else if (
      location.pathname == "/vrdriving_corporate_outreach" ||
      location.pathname == "/vr_driver"
    ) {
      return "max-md:vrdriver_mb_bg";
    } else if (location.pathname == "/ARSOME_Ventures") {
      return "max-md:venture_mb_bg";
    } else if (location.pathname == "/process") {
      return "max-md:process_mb_bg";
    } else if (location.pathname == "/meet_the_team") {
      return "max-md:team_mb_bg";
    }
  }, [location.pathname]);

  return (
    <header className={`md:mb-6 xl:mb-8 bg-white sticky top-0 z-50`}>
      {/** ipad, desktop mode */}
      <nav className="justify-center hidden p-0 xl:justify-between max-xl:hidden xl:flex md_menu_bg xl:px-5">
        <NavLink to={"/"} className={"max-xl:hidden xl:flex items-center"}>
          <img
            src={
              location.pathname == "/ARSOME_Ventures" ? venture_log : white_log
            }
            alt="ARSOME company Logo"
            className="hidden md:block md:w-[130px] "
          />
        </NavLink>

        <div className="flex items-center justify-center space-x-4">
          <LinkItem
            link="/"
            title="Home"
            selected={location.pathname}
            isCurOpen={selectItem === 0}
          />
          <SubMenuItem
            title="ARSOME™"
            menuItems={companyItems}
            keyNum={1}
            curKey={selectItem}
            isDesktopMod={true}
            selected={location.pathname}
            isCurLink={
              companyItems.findIndex((it) => it.url === location.pathname) > -1
            }
            setIsOpened={(num) => setSelectItem(num)}
          />
          <LinkItem
            link="/ar_vr_custom_development"
            title="AR/VR Tailor-Made Training"
            selected={location.pathname}
            isCurOpen={selectItem === 0}
          />
          <div>
            <button
              className="transition ease-in-out hover:bg-[#383231] hover:text-white hover:border-[#383231] px-4 py-2 font-bold bg-white rounded-md text-btn_blue sm:text-sm lg:text-base"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Book Demo
            </button>
          </div>
          {/* <LinkItem
            link="#"
            title="ARSOME Ventures™"
            goLink={() => {
              window.open('https://arsomeventures.com/', '_blank');
            }}
            selected={location.pathname}
            isCurOpen={selectItem === 0}
          /> */}
        </div>
      </nav>
      <nav className="items-center hidden p-0 justify-evenly max-md:hidden md:flex xl:hidden md_menu_bg">
        <LinkItem
          link="/"
          title="Home"
          selected={location.pathname}
          isCurOpen={selectItem === 0}
        />
        <SubMenuItem
          title="ARSOME™"
          menuItems={companyItems}
          keyNum={1}
          curKey={selectItem}
          selected={location.pathname}
          isCurLink={
            companyItems.findIndex((it) => it.url === location.pathname) > -1
          }
          setIsOpened={(num) => setSelectItem(num)}
        />
        <LinkItem
          link="/ar_vr_custom_development"
          title="AR/VR Tailor-Made Training"
          selected={location.pathname}
          isCurOpen={selectItem === 0}
        />
        <div>
          <button
            className="px-4 py-2 font-bold bg-white rounded-md text-btn_blue sm:text-sm lg:text-base"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Book Demo
          </button>
        </div>
        {/* <LinkItem
            link="#"
            title="ARSOME Ventures™"
            goLink={() => {
              window.open('https://arsomeventures.com/', '_blank');
            }}
            selected={location.pathname}
            isCurOpen={selectItem === 0}
          /> */}
      </nav>
      {/** ipad, desktop mode */}
      {/** mobile response mode */}
      <nav
        className={`md:hidden max-md:block max-md:sticky max-md:top-0 max-md:z-60  ${
          isOpen ? "md_menu_bg" : ""
        } `}
      >
        {isOpen ? (
          <div className="pt-4">
            <div className="flex justify-between px-5">
              <div>
                <img
                  src={mobile_mark}
                  alt="ARSOME company Logo"
                  className="h-[50px]"
                />
              </div>
              <div>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  onClick={() => setIsOpen(false)}
                >
                  <path
                    fillRule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>
            <div className="pt-2 pb-4">
              <LinkItem
                link="/"
                title="Home"
                selected={location.pathname}
                isCurOpen={selectItem === 0}
                goLink={() => {
                  setSelectItem(0);
                  setIsOpen(false);
                }}
              />
              <SunMbMenuItem
                title="ARSOME™"
                menuItems={companyItems}
                curKey={selectItem}
                isCurLink={
                  companyItems.findIndex((it) => it.url === location.pathname) >
                  -1
                }
                keyNum={1}
                onAfterLink={() => setIsOpen(false)}
                setIsOpened={(num) => setSelectItem(num)}
              />
              <LinkItem
                link="/ar_vr_custom_development"
                title="AR/VR Tailor-Made Training"
                selected={location.pathname}
                isCurOpen={selectItem === 3}
                goLink={() => {
                  setSelectItem(3);
                  setIsOpen(false);
                }}
              />
              {/* <LinkItem
                link="#"
                title="ARSOME Ventures™"
                selected={location.pathname}
                isCurOpen={selectItem === 0}
                goLink={() => {
                  window.open('https://arsomeventures.com/', '_blank');
                }}
              /> */}
              <div className="flex items-center justify-center my-3">
                <button
                  className="px-4 py-2 font-bold bg-white rounded-md text-btn_blue sm:text-sm lg:text-base"
                  onClick={() => {
                    navigate("/contact");
                    setIsOpen(false);
                  }}
                >
                  Book Demo
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-5 py-4 h-[82px] md_menu_bg w-full flex justify-between">
            <NavLink to={"/"} className="flex items-center">
              <img
                src={mobile_mark}
                alt="ARSOME company Mobile Logo"
                className="h-[50px]"
              />
            </NavLink>
            <div className="flex items-center">
              <button
                type="button"
                className="block text-white focus:outline-none"
                onClick={() => {
                  setIsOpen((prev) => !prev);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 18H3V16H21V18ZM21 13H3V11H21V13ZM21 8H3V6H21V8Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </nav>
      {/* {location.pathname != "/Are_We_A_Fit" &&
        location.pathname != "/Community" && (
          <HeroWrap className="max-md:block md:hidden">{HeroArea()}</HeroWrap>
        )} */}
    </header>
  );
};
